var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Grid",
        {
          ref: "grid",
          attrs: {
            "data-items": _vm.result,
            filterable: true,
            filter: _vm.filter,
            "edit-field": "inEdit",
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            columns: _vm.columns,
            "selected-field": _vm.selectedField,
          },
          on: {
            filterchange: _vm.filterChange,
            sortchange: _vm.sortChangeHandler,
            itemchange: _vm.itemChange,
            dataStateChange: _vm.dataStateChange,
            pagechange: _vm.pageChange,
            rowclick: _vm.onRowClick,
          },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", {
                    attrs: { "data-item": props.dataItem },
                    on: {
                      edit: _vm.edit,
                      save: _vm.save,
                      remove: _vm.remove,
                      cancel: _vm.cancel,
                    },
                  }),
                ]
              },
            },
            {
              key: "myDropDownCell",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("ddcell", {
                    attrs: { "data-item": props.dataItem, field: props.field },
                    on: {
                      change: function (e) {
                        return _vm.ddChange(e, props.dataItem)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c("grid-toolbar", [
            _vm.hasItemsInEdit
              ? _c(
                  "button",
                  {
                    staticClass: "k-button",
                    attrs: { title: "Cancel current changes" },
                    on: { click: _vm.cancelChanges },
                  },
                  [_vm._v(" Cancel current changes ")]
                )
              : _vm._e(),
          ]),
          _c("grid-norecords", [_vm._v("There is no data available custom ")]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1250px" },
          model: {
            value: _vm.visibleDialog,
            callback: function ($$v) {
              _vm.visibleDialog = $$v
            },
            expression: "visibleDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "button",
                {
                  staticClass: "v-btn white",
                  attrs: { id: "closeX_btn" },
                  on: {
                    click: function ($event) {
                      _vm.alert = true
                    },
                  },
                },
                [_vm._v(" ✖ ")]
              ),
              _c("v-card-title", { staticClass: "text-h4" }, [
                _vm._v("Case Edit"),
              ]),
              _c(
                "v-alert",
                { attrs: { value: _vm.alert, prominent: "", type: "warning" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          " This case has not been saved. Would you like to continue without saving? "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c("v-btn", { on: { click: _vm.toggleDialog } }, [
                            _vm._v("Exit Without Saving"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticStyle: { padding: "3px" } },
                    [
                      _c(
                        "v-row",
                        { staticStyle: { padding: "3px" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.sites,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Site",
                                  readonly: "",
                                  rules: [
                                    function (v) {
                                      return !!v || "Site is required"
                                    },
                                  ],
                                },
                                on: { change: _vm.siteSelect },
                                model: {
                                  value: _vm.siteId,
                                  callback: function ($$v) {
                                    _vm.siteId = $$v
                                  },
                                  expression: "siteId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Subject", readonly: "" },
                                model: {
                                  value: _vm.subject_edit,
                                  callback: function ($$v) {
                                    _vm.subject_edit = $$v
                                  },
                                  expression: "subject_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.casestatus,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Status",
                                  "allow-overflow": true,
                                  rules: [
                                    function (v) {
                                      return !!v || "Status is required"
                                    },
                                  ],
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.caseStatusEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.caseStatusChange,
                                },
                                model: {
                                  value: _vm.caseStatus,
                                  callback: function ($$v) {
                                    _vm.caseStatus = $$v
                                  },
                                  expression: "caseStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Case ID", readonly: "" },
                                model: {
                                  value: _vm.id_edit,
                                  callback: function ($$v) {
                                    _vm.id_edit = $$v
                                  },
                                  expression: "id_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  label: "Date Closed",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  value: _vm.dateClosedDisp,
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.date_closed_edit,
                                    callback: function ($$v) {
                                      _vm.date_closed_edit = $$v
                                    },
                                    expression: "date_closed_edit",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      locale: "en-in",
                                      "no-title": "",
                                      readonly: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.dateClosedMenu = false
                                      },
                                    },
                                    model: {
                                      value: _vm.dateClosedVal,
                                      callback: function ($$v) {
                                        _vm.dateClosedVal = $$v
                                      },
                                      expression: "dateClosedVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "", label: "Downtime Days" },
                                model: {
                                  value: _vm.downtime_days_edit,
                                  callback: function ($$v) {
                                    _vm.downtime_days_edit = $$v
                                  },
                                  expression: "downtime_days_edit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  label: "Monitoring Platform",
                                },
                                model: {
                                  value: _vm.monitoring_plat_edit,
                                  callback: function ($$v) {
                                    _vm.monitoring_plat_edit = $$v
                                  },
                                  expression: "monitoring_plat_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Downtime Pct", readonly: "" },
                                model: {
                                  value: _vm.downtime_pct_edit,
                                  callback: function ($$v) {
                                    _vm.downtime_pct_edit = $$v
                                  },
                                  expression: "downtime_pct_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  label: "Lost production",
                                },
                                model: {
                                  value: _vm.lost_production_edit,
                                  callback: function ($$v) {
                                    _vm.lost_production_edit = $$v
                                  },
                                  expression: "lost_production_edit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  readonly: "",
                                  items: _vm.casecategories,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "allow-overflow": true,
                                  label: "Select Category",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.caseCategoryEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.caseCategoryChange,
                                },
                                model: {
                                  value: _vm.caseCategory,
                                  callback: function ($$v) {
                                    _vm.caseCategory = $$v
                                  },
                                  expression: "caseCategory",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  readonly: "",
                                  items: _vm.casepriorities,
                                  "allow-overflow": true,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Priority",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.casePriorityEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  change: _vm.casePriorityChange,
                                },
                                model: {
                                  value: _vm.casePriority,
                                  callback: function ($$v) {
                                    _vm.casePriority = $$v
                                  },
                                  expression: "casePriority",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  readonly: "",
                                  items: _vm.contacts,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select Service Team",
                                },
                                on: { change: _vm.contactChange },
                                model: {
                                  value: _vm.contact,
                                  callback: function ($$v) {
                                    _vm.contact = $$v
                                  },
                                  expression: "contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "", label: "Vendor Case #" },
                                model: {
                                  value: _vm.vendor_case_num_edit,
                                  callback: function ($$v) {
                                    _vm.vendor_case_num_edit = $$v
                                  },
                                  expression: "vendor_case_num_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Address", readonly: "" },
                                model: {
                                  value: _vm.address_line_1_edit,
                                  callback: function ($$v) {
                                    _vm.address_line_1_edit = $$v
                                  },
                                  expression: "address_line_1_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "", label: "City" },
                                model: {
                                  value: _vm.city_edit,
                                  callback: function ($$v) {
                                    _vm.city_edit = $$v
                                  },
                                  expression: "city_edit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-col", {
                            staticStyle: { padding: "3px" },
                            attrs: { cols: "12", md: "4" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "3px" },
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              this.id_edit != null
                                ? _c("case-notev-2", {
                                    attrs: {
                                      case_id: this.id_edit,
                                      site_plans: this.edit_item.site.sitePlans,
                                      system:
                                        this.edit_item.site.monitoringSystem,
                                      nativeLink:
                                        this.edit_item.site
                                          .nativeMonitoringLink,
                                      site_id: this.siteId,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-1" },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-success\n                  bg-gradient-success\n                ",
                                  attrs: { ripple: false, elevation: 0 },
                                  on: { click: _vm.saveCase },
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-success\n                  bg-gradient-success\n                  ml-1\n                ",
                                  attrs: { ripple: false, elevation: 0 },
                                  on: { click: _vm.saveCloseCase },
                                },
                                [_vm._v(" Save And Close ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.user.claims.user_role ==
                                          "super_user" ||
                                        _vm.user.claims.user_role ==
                                          "customer_admin",
                                      expression:
                                        "\n                  user.claims.user_role == 'super_user' ||\n                  user.claims.user_role == 'customer_admin'\n                ",
                                    },
                                  ],
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-default\n                  bg-gradient-danger\n                  ml-1\n                ",
                                  attrs: { ripple: false, elevation: 0 },
                                  on: { click: _vm.deleteCase },
                                },
                                [_vm._v(" Delete ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }