<template>
  <div>
    <Grid
      ref="grid"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
      :selected-field="selectedField"
      @rowclick="onRowClick"
    >
      <template v-slot:myTemplate="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <template v-slot:myDropDownCell="{ props }">
        <ddcell
          :data-item="props.dataItem"
          :field="props.field"
          @change="(e) => ddChange(e, props.dataItem)"
        />
      </template>
      <grid-toolbar>
        <!-- <button title="Add new" class="k-button k-primary" @click="insert">
          Add new
        </button> -->
        <button
          v-if="hasItemsInEdit"
          title="Cancel current changes"
          class="k-button"
          @click="cancelChanges"
        >
          Cancel current changes
        </button>
      </grid-toolbar>
      <grid-norecords>There is no data available custom </grid-norecords>
    </Grid>
    <v-dialog
      v-model="visibleDialog"
      max-width="1250px"
    >
      <v-card>
        <button class="v-btn white" id="closeX_btn" @click="alert = true">
          &#10006;
        </button>
        <v-card-title class="text-h4">Case Edit</v-card-title>
        <v-alert
          :value="alert"
          prominent
          type="warning"
        >
          <v-row align="center">
            <v-col class="grow">
              This case has not been saved. Would you like to continue without saving?
            </v-col>
            <v-col class="shrink">
              <v-btn @click="toggleDialog">Exit Without Saving</v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-form v-model="valid">
          <v-container style="padding: 3px">
            <v-row style="padding: 3px">
              <!-- site -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-autocomplete
                  v-model="siteId"
                  :items="sites"
                  item-text="name"
                  item-value="id"
                  label="Select Site"
                  readonly
                  @change="siteSelect"
                  :rules="[(v) => !!v || 'Site is required']"
                ></v-autocomplete>
              </v-col>
              <!-- subject -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  v-model="subject_edit"
                  label="Subject"
                  readonly
                ></v-text-field>
              </v-col>
              <!-- case status -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-autocomplete
                  v-model="caseStatus"
                  :items="casestatus"
                  item-text="name"
                  item-value="id"
                  label="Select Status"
                  :allow-overflow="true"
                  @keydown.enter="caseStatusEnter"
                  @change="caseStatusChange"
                  :rules="[(v) => !!v || 'Status is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <!-- case id -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  v-model="id_edit"
                  label="Case ID"
                  readonly
                ></v-text-field>
              </v-col>
              <!-- date closed -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-menu
                  v-model="date_closed_edit"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Date Closed"
                      prepend-icon="event"
                      readonly
                      :value="dateClosedDisp"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="en-in"
                    v-model="dateClosedVal"
                    no-title
                    readonly
                    @input="dateClosedMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12"  style="padding: 3px;" md="4">
                <v-text-field
                  v-model="kw_affected_edit"
                  label="Kw affected"
                  required
                ></v-text-field>
              </v-col> -->

              <!-- downtime days -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  readonly
                  v-model="downtime_days_edit"
                  label="Downtime Days"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- monitoring platform -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  readonly
                  v-model="monitoring_plat_edit"
                  label="Monitoring Platform"
                ></v-text-field>
              </v-col>
              <!-- downtime pct -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  v-model="downtime_pct_edit"
                  label="Downtime Pct"
                  readonly
                ></v-text-field>
              </v-col>
              <!-- lost production -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  readonly
                  v-model="lost_production_edit"
                  label="Lost production"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!--case category -->
              <v-col cols="12" style="padding: 3px" md="4">
                <v-autocomplete
                  readonly
                  v-model="caseCategory"
                  :items="casecategories"
                  item-text="name"
                  item-value="id"
                  :allow-overflow="true"
                  @keydown.enter="caseCategoryEnter"
                  @change="caseCategoryChange"
                  label="Select Category"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="padding: 3px" md="4">
                <v-autocomplete
                  readonly
                  v-model="casePriority"
                  :items="casepriorities"
                  :allow-overflow="true"
                  @keydown.enter="casePriorityEnter"
                  @change="casePriorityChange"
                  item-text="name"
                  item-value="id"
                  label="Select Priority"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="padding: 3px" md="4">
                <v-autocomplete
                  readonly
                  v-model="contact"
                  :items="contacts"
                  item-text="name"
                  item-value="id"
                  @change="contactChange"
                  label="Select Service Team"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  readonly
                  v-model="vendor_case_num_edit"
                  label="Vendor Case #"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field
                  v-model="address_line_1_edit"
                  label="Address"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="padding: 3px" md="4">
                <v-text-field v-model="city_edit" readonly label="City">
                </v-text-field>
              </v-col>
              <v-col cols="12" style="padding: 3px" md="4">
                <!-- <combobox
                    :style="{ width: '125px' }"
                    :data-items="reporting_groups"
                    :default-item="defaultItem"
                    :text-field="'name'"
                    :data-item-key="'id'"
                    :value="value"
                    @change="handleChange"
                    :allow-custom="true"
                ></combobox> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding: 3px" md="12">
                <case-notev-2
                  v-if="this.id_edit != null"
                  v-bind:case_id="this.id_edit"
                  v-bind:site_plans="this.edit_item.site.sitePlans"
                  v-bind:system="this.edit_item.site.monitoringSystem"
                  v-bind:nativeLink="this.edit_item.site.nativeMonitoringLink"
                  v-bind:site_id="this.siteId"
                >
                </case-notev-2>
              </v-col>
            </v-row>
            <v-row class="mb-1">
              <v-col>
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-success
                    bg-gradient-success
                  "
                  @click="saveCase"
                >
                  Save
                </v-btn>
                <v-btn
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-success
                    bg-gradient-success
                    ml-1
                  "
                  @click="saveCloseCase"
                >
                  Save And Close
                </v-btn>
                <v-btn
                  v-show="
                    user.claims.user_role == 'super_user' ||
                    user.claims.user_role == 'customer_admin'
                  "
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-danger
                    ml-1
                  "
                  @click="deleteCase"
                >
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import {
  DropDownList,
  ComboBox,
  AutoComplete,
} from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import CommandCell from "./CommandCell";
import DropDownCell from "./DropDownCell.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
import CaseNotev2 from "./CaseNotev2.vue";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    autocomplete: AutoComplete,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "k-dialog": Dialog,
    "k-input": Input,
    "dialog-actions-bar": DialogActionsBar,
    ddcell: DropDownCell,
    custom: CommandCell,
    dropdownlist: DropDownList,
    combobox: ComboBox,
    CaseNotev2,
  },
  data: function () {
    return {
      alert: false,
      value: [],
      reporting_groups: [
        { id: 1, name: "foo" },
        { id: 2, name: "bar" },
      ],
      defaultItem: {
        name: "Select Group ...",
        id: 0,
      },
      sites: [],
      contacts: [],
      casestatus: [],
      casecategories: [],
      casepriorities: [],
      caseStatus: null,
      caseCategory: null,
      casePriority: null,
      siteId: [],
      contact: null,
      defaultItem: {
        name: "Select Site ...",
        id: 0,
      },
      dateAddedMenu: null,
      dateAddedVal: null,
      dateClosedMenu: null,
      dateClosedVal: null,
      site_edit_value: "",
      valid: true,
      edit_item: null,
      id_edit: null,
      subject_edit: null,
      case_category_edit: null,
      //   date_added_edit: null,
      date_closed_edit: null,
      kw_affected_edit: null,
      downtime_days_edit: null,
      downtime_pct_edit: null,
      lost_production_edit: null,
      site_edit: null,
      account_edit: null,
      case_status_edit: null,
      vendor_case_num_edit: null,
      monitoring_plat_edit: null,
      subject_edit: null,
      address_line_1_edit: null,
      city_edit: null,
      visibleDialog: false,
      filter: null,
      sort: null,
      pageable: { pageSizes: [10, 20, 50, 100] },
      gridData: [],
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      updatedData: [],
      selectedID: 1,
      selectedField: "selected",
      editID: null,
      sort: [
        { field: "subject", dir: "asc" },
        { field: "date_closed", dir: "asc" },
      ],
      columns: [
        { field: "site.name", title: "Site" },
        { field: "id", hidden: true, editable: false, title: "ID" },
        { field: "subject", title: "Subject" },
        // {
        //   field: "date_added",
        //   editable: true,
        //   format: "{0:d}",
        //   title: "Date Added",
        // },
        {
          field: "date_closed",
          editable: true,
          format: "{0:d}",
          title: "Date Closed",
        },
        // { field: "kw_affected", filterable: false, title: "Kw Affected", width: "100" },
        { field: "downtime_days", title: "Downtime Days" },
        { field: "downtime_pct", title: "Downtime Pct" },
        {
          field: "lost_production",
          filterable: false,
          title: "Lost Production",
          width: "100",
        },
        // { field: "case_status.name", title: "Status" },
        { field: "case_category.name", title: "Category" },
        { field: "site.managingCases", title: "Case Managed" },
        { field: "contact.name", title: "Onsite Service Team" },
        { field: "vendor_case_num", title: "Vendor Case #" },
        // { field: "case_priority.name", title: "Priority" },
        // { field: "site.customerAccountName", title: "Account" },
        // { field: "status", title: "Status"}
        // { cell: "myTemplate", filterable: false, width: "210px" },
      ],
    };
  },
  computed: {
    // dateAddedDisp() {
    //   return this.dateAddedVal;
    // },
    user: function () {
      return this.$store.getters.currentUser;
    },
    dateClosedDisp() {
      return this.dateClosedVal;
    },
    result: {
      get: function () {
        return filterBy(orderBy(this.gridData, this.sort), this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
  },
  created: function () {
    this.getData();
    this.loadSites();
    this.loadCaseStatus();
    this.loadCaseCategories();
    this.loadCasePriorities();
    this.loadContacts();
  },
  mounted() {
    document.title = "Closed Cases"
  },
  methods: {
    caseCategoryEnter(e) {
      console.log("event");
      const newVal = e.target.value;
      const found = this.casecategories.find((x) => x.name == newVal);
      if (!found) {
        //add to db and list
        Service.createCaseCategory({ name: newVal })
          .then((response) => {
            const newCat = response.data;
            this.casecategories.push(newCat);
            this.caseCategory = newCat;
            Vue.$toast.success(`Category created`, {
              position: "top-right",
            });
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {
              position: "top-right",
            });
            console.error(error);
          });
      }
    },
    casePriorityEnter(e) {
      const newVal = e.target.value;
      const found = this.casepriorities.find((x) => x.name == newVal);
      if (!found) {
        //add to db and list
        Service.createCasePriority({ name: newVal })
          .then((response) => {
            const newP = response.data;
            this.casepriorities.push(newP);
            this.casePriority = newP;
            Vue.$toast.success(`Priority created`, {
              position: "top-right",
            });
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {
              position: "top-right",
            });
            console.error(error);
          });
      }
    },
    contactChange(contact_id) {
      const found = this.contacts.find((x) => x.id == contact_id);
      this.contact = found;
    },
    caseStatusChange(case_status_id) {
      const found = this.casestatus.find((x) => x.id == case_status_id);
      this.caseStatus = found;
    },
    caseCategoryChange(case_category_id) {
      const found = this.casecategories.find((x) => x.id == case_category_id);
      this.caseCategory = found;
    },
    casePriorityChange(case_priority_id) {
      const found = this.casepriorities.find((x) => x.id == case_priority_id);
      this.casePriority = found;
    },
    caseStatusEnter(e) {
      const newVal = e.target.value;
      const found = this.casestatus.find((x) => x.name == newVal);
      if (!found) {
        //add to db and list
        Service.createCaseStatus({ name: newVal })
          .then((response) => {
            const newStatus = response.data;
            this.casestatus.push(newStatus);
            this.caseStatus = newStatus;
            Vue.$toast.success(`Status created`, {
              position: "top-right",
            });
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {
              position: "top-right",
            });
            console.error(error);
          });
      }
    },
    loadContacts() {
      Service.getContacts()
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadCaseStatus() {
      Service.getCaseStatus()
        .then((response) => {
          this.casestatus = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadCaseCategories() {
      Service.getCaseCategories()
        .then((response) => {
          this.casecategories = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadCasePriorities() {
      Service.getCasePriorities()
        .then((response) => {
          this.casepriorities = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadSites() {
      Service.getAllSites()
        .then((response) => {
          this.sites = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading sites data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    siteSelect(site_id) {
      // update address and city
      const site = this.sites.find((x) => x.id == site_id);
      this.address_line_1_edit = site.address.street;
      this.city_edit = site.address.city;
    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
      this.alert = false;
    },
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    deleteCase() {
      const event = {
        dataItem: this.edit_item,
      };
      this.remove(event);
    },
    saveCase() {
      console.log("Save Case...");
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, this.edit_item);

      item.subject = this.subject_edit;
      //   item.date_added = this.dateAddedVal;
      item.date_closed = this.dateClosedVal;

      //feature/583
      if (this.kw_affected_edit != null) {
        item.kw_affected = this.kw_affected_edit;
      } else {
        item.kw_affected = 0;
      }

      // item.downtime_days = this.downtime_days_edit;
      //feature/583
      if (this.downtime_pct_edit != null) {
        item.downtime_pct = parseFloat(this.downtime_pct_edit).toFixed(2);
      } else {
        item.downtime_pct = 0;
      }

      //feature/583
      if (this.lost_production_edit != null) {
        item.lost_production = this.lost_production_edit;
      } else {
        item.lost_production = 0;
      }

      item.site_id = this.siteId;
      if (item.site) {
        item.site.name = this.sites.find((s) => s.id == this.siteId).name;
      }

      // status
      if (this.caseStatus) {
        item.case_status = this.caseStatus;
        item.case_status_id = this.caseStatus.id;
      }

      // category
      if (this.caseCategory) {
        item.case_category = this.caseCategory;
        item.case_category_id = this.caseCategory.id;
      }

      // priority
      if (this.casePriority) {
        item.case_priority = this.casePriority;
        item.case_priority_id = this.casePriority.id;
      }

      if (this.contact) {
        item.contact = this.contact;
        item.contact.id = this.contact.id;
      }

      item.vendor_case_num = this.vendor_case_num_edit;
      // item.notes = this.notes_edit;
      item.address_line_1 = this.address_line_1_edit;
      item.city = this.city_edit;
      // item.contact = e.target.value;
      this.gridData = updatedData;
      this.alert = false;
      const event = {
        dataItem: item,
      };
      this.save(event);
    },
    saveCloseCase() {
      console.log("Save Case...");
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, this.edit_item);

      item.subject = this.subject_edit;
      //   item.date_added = this.dateAddedVal;
      item.date_closed = this.dateClosedVal;

      //feature/583
      if (this.kw_affected_edit != null) {
        item.kw_affected = this.kw_affected_edit;
      } else {
        item.kw_affected = 0;
      }

      // item.downtime_days = this.downtime_days_edit;

      //feature/583
      if (this.downtime_pct_edit != null) {
        item.downtime_pct = parseFloat(this.downtime_pct_edit).toFixed(2);
      } else {
        item.downtime_pct = 0;
      }

      //feature/583
      if (this.lost_production_edit != null) {
        item.lost_production = this.lost_production_edit;
      } else {
        item.lost_production = 0;
      }

      item.site_id = this.siteId;
      if (item.site) {
        item.site.name = this.sites.find((s) => s.id == this.siteId).name;
      }

      // status
      if (this.caseStatus) {
        item.case_status = this.caseStatus;
        item.case_status_id = this.caseStatus.id;
      }

      // category
      if (this.caseCategory) {
        item.case_category = this.caseCategory;
        item.case_category_id = this.caseCategory.id;
      }

      // priority
      if (this.casePriority) {
        item.case_priority = this.casePriority;
        item.case_priority_id = this.casePriority.id;
      }

      if (this.contact) {
        item.contact = this.contact;
        item.contact.id = this.contact.id;
      }

      item.vendor_case_num = this.vendor_case_num_edit;
      // item.notes = this.notes_edit;
      item.address_line_1 = this.address_line_1_edit;
      item.city = this.city_edit;
      // item.contact = e.target.value;
      this.gridData = updatedData;
      this.alert = false;
      const event = {
        dataItem: item,
      };
      this.save(event);
      this.toggleDialog();
    },
    onClickOutside() {
      this.toggleDialog();
    },
    calcDowntimeDays(downtime_num_json) {
      this.downtime_days_edit = "";
      if (downtime_num_json) {
        const downtime_numbers = new Map(Object.entries(downtime_num_json));
        downtime_numbers.forEach((value, key) => {
          switch (key) {
            case "1":
              const val1 = downtime_numbers.get("1")["qty"];
              this.downtime_days_edit += "Q1: " + val1;
              break;
            case "2":
              const val2 = downtime_numbers.get("2")["qty"];
              this.downtime_days_edit += " Q2: " + val2;
              break;
            case "3":
              const val3 = downtime_numbers.get("3")["qty"];
              this.downtime_days_edit += " Q3: " + val3;
              break;
            case "4":
              const val4 = downtime_numbers.get("4")["qty"];
              this.downtime_days_edit += " Q4: " + val4;
              break;
          }
        });
      }
    },
    getData: function () {
      NProgress.start()
      Service.getCasesByStatus("Done")
        .then((response) => {
          this.gridData = response.data;
          console.log(this.gridData)
          NProgress.done()
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
          NProgress.done()
        });
    },
    onRowClick(event) {
      // this.selectedID = event.dataItem.id;
      // set edit item
      this.resetFormValues();
      this.edit_item = event.dataItem;

      this.id_edit = event.dataItem.id;
      this.subject_edit = event.dataItem.subject;
      this.siteId = event.dataItem.site.id;
      this.caseStatus = event.dataItem.case_status;
      this.caseCategory = event.dataItem.case_category;
      this.casePriority = event.dataItem.case_priority;
      //   this.date_added_edit = event.dataItem.date_added;
      //   this.dateAddedVal = this.$moment(event.dataItem.date_added).format(
      //     "YYYY-MM-DD"
      //   );
      this.dateClosedVal = this.$moment(event.dataItem.date_closed).format(
        "YYYY-MM-DD"
      );
      this.date_closed_edit = event.dataItem.date_closed;
      this.kw_affected_edit = event.dataItem.kw_affected;
      // this.downtime_days_edit = event.dataItem.downtime_days;
      this.calcDowntimeDays(event.dataItem.downtime_numbers);
      this.downtime_pct_edit = event.dataItem.downtime_pct;
      this.lost_production_edit = event.dataItem.lost_production;
      this.contact = event.dataItem.contact;
      // lookup address/city from site id
      // const site = this.sites.find(s => s.id == this.siteId)
      this.address_line_1_edit = event.dataItem.address_line_1;
      this.city_edit = event.dataItem.city;
      this.vendor_case_num_edit = event.dataItem.vendor_case_num;

      if (this.edit_item.site) {
        this.monitoring_plat_edit = this.edit_item.site.monitoringSystem;
      }

      console.log(`selected ${this.selectedID}`);
      this.toggleDialog();
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },

    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    resetFormValues() {
      var date = new Date();
      // this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
      this.edit_item = null;
      this.id_edit = null;
      this.subject_edit = null;
      this.siteId = null;
      this.caseStatus = null;
      this.caseCategory = null;
      this.casePriority = null;
      this.date_added_edit = null;
      this.dateAddedVal = this.$moment(date).format("YYYY-MM-DD");
      this.dateClosedVal = this.$moment(date).format("YYYY-MM-DD");
      this.date_closed_edit = null;
      this.kw_affected_edit = null;
      this.downtime_days_edit = null;
      this.downtime_pct_edit = null;
      this.lost_production_edit = null;

      this.contact = null;
      this.vendor_case_num_edit = null;
      this.city_edit = null;
      this.address_line_1_edit = null;
    },
    insert() {
      // reset values
      this.resetFormValues();
      this.toggleDialog();
      const dataItem = {};
      this.edit_item = dataItem;
      const newCases = this.gridData.slice();
      newCases.unshift(dataItem);
      this.update(newCases, dataItem);
      this.gridData = newCases;
    },
    edit: function (e) {
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    save: function (e) {
      let caseID = e.dataItem.id;
      let index = this.gridData.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(e.dataItem) ||
          (caseID && c.id === caseID)
      );
      let item = this.gridData[index];
      let updated = Object.assign(this.update(this.gridData.slice(), item), {
        inEdit: undefined,
      });
      if (updated.id == undefined) {
        Service.createCase(updated)
          .then((response) => {
            //this.toggleDialog();
            Vue.$toast.success(`Case created`, {
              position: "top-right",
              duration: 4000,
            });
            updated.id = response.data.id;

            this.gridData.splice(index, 1, updated);
            this.getData();
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        if (updated.case_status.name != "Done") {
          // set closed date
          updated.date_closed = null;
        }
        Service.updateCase(updated.id, updated)
          .then((response) => {
            //this.toggleDialog();
            Vue.$toast.success(`Case updated`, {
              position: "top-right",
              duration: 4000,
            });
            const c = response.data;
            this.gridData.splice(index, 1, updated);
            if (c.case_status.name != "Done") {
              this.getData();
            }
          })
          .catch((error) => {
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    update(data, item, remove) {
      let updated;
      let caseID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (caseID && c.id === caseID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );

        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteCase(e.dataItem.id)
        .then((response) => {
          this.toggleDialog();
          Vue.$toast.warning(`Case removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
  },
};
</script>

<style>
#closeX_btn {
  float: right;
  width: 48px;
  height: 48px;
}
</style>